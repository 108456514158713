<template>
  <div class="mb-10">
    <!--begin::Heading-->
    <h2 class="anchor fw-bolder mb-5">
      <a href="#custom-option-content"></a>
      Custom option content
    </h2>
    <!--end::Heading-->

    <!--begin::Block-->
    <div class="py-5">
      You can customize the content of cascader node.
    </div>
    <!--end::Block-->

    <div class="rounded border p-10">
      <el-cascader :options="options">
        <template #default="{ node, data }">
          <span>{{ data.label }}</span>
          <span v-if="!node.isLeaf"> ({{ data.children.length }}) </span>
        </template>
      </el-cascader>

      <CodeHighlighter :field-height="400" lang="html">{{
        code9
      }}</CodeHighlighter>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import CodeHighlighter from "@/view/content/highlighters/CodeHighlighter";
import { code9 } from "./data.ts";

export default defineComponent({
  name: "custom-option-content",
  data() {
    return {
      options: [
        {
          value: "guide",
          label: "Guide",
          children: [
            {
              value: "disciplines",
              label: "Disciplines",
              children: [
                {
                  value: "consistency",
                  label: "Consistency"
                },
                {
                  value: "feedback",
                  label: "Feedback"
                },
                {
                  value: "efficiency",
                  label: "Efficiency"
                },
                {
                  value: "controllability",
                  label: "Controllability"
                }
              ]
            },
            {
              value: "navigation",
              label: "Navigation",
              children: [
                {
                  value: "side nav",
                  label: "Side Navigation"
                },
                {
                  value: "top nav",
                  label: "Top Navigation"
                }
              ]
            }
          ]
        },
        {
          value: "component",
          label: "Component",
          children: [
            {
              value: "basic",
              label: "Basic",
              children: [
                {
                  value: "layout",
                  label: "Layout"
                },
                {
                  value: "color",
                  label: "Color"
                },
                {
                  value: "typography",
                  label: "Typography"
                },
                {
                  value: "icon",
                  label: "Icon"
                },
                {
                  value: "button",
                  label: "Button"
                }
              ]
            },
            {
              value: "form",
              label: "Form",
              children: [
                {
                  value: "radio",
                  label: "Radio"
                },
                {
                  value: "checkbox",
                  label: "Checkbox"
                },
                {
                  value: "input",
                  label: "Input"
                },
                {
                  value: "input-number",
                  label: "InputNumber"
                },
                {
                  value: "select",
                  label: "Select"
                },
                {
                  value: "cascader",
                  label: "Cascader"
                },
                {
                  value: "switch",
                  label: "Switch"
                },
                {
                  value: "slider",
                  label: "Slider"
                },
                {
                  value: "time-picker",
                  label: "TimePicker"
                },
                {
                  value: "date-picker",
                  label: "DatePicker"
                },
                {
                  value: "datetime-picker",
                  label: "DateTimePicker"
                },
                {
                  value: "upload",
                  label: "Upload"
                },
                {
                  value: "rate",
                  label: "Rate"
                },
                {
                  value: "form",
                  label: "Form"
                }
              ]
            },
            {
              value: "data",
              label: "Data",
              children: [
                {
                  value: "table",
                  label: "Table"
                },
                {
                  value: "tag",
                  label: "Tag"
                },
                {
                  value: "progress",
                  label: "Progress"
                },
                {
                  value: "tree",
                  label: "Tree"
                },
                {
                  value: "pagination",
                  label: "Pagination"
                },
                {
                  value: "badge",
                  label: "Badge"
                }
              ]
            },
            {
              value: "notice",
              label: "Notice",
              children: [
                {
                  value: "alert",
                  label: "Alert"
                },
                {
                  value: "loading",
                  label: "Loading"
                },
                {
                  value: "message",
                  label: "Message"
                },
                {
                  value: "message-box",
                  label: "MessageBox"
                },
                {
                  value: "notification",
                  label: "Notification"
                }
              ]
            },
            {
              value: "navigation",
              label: "Navigation",
              children: [
                {
                  value: "menu",
                  label: "NavMenu"
                },
                {
                  value: "tabs",
                  label: "Tabs"
                },
                {
                  value: "breadcrumb",
                  label: "Breadcrumb"
                },
                {
                  value: "dropdown",
                  label: "Dropdown"
                },
                {
                  value: "steps",
                  label: "Steps"
                }
              ]
            },
            {
              value: "others",
              label: "Others",
              children: [
                {
                  value: "dialog",
                  label: "Dialog"
                },
                {
                  value: "tooltip",
                  label: "Tooltip"
                },
                {
                  value: "popover",
                  label: "Popover"
                },
                {
                  value: "card",
                  label: "Card"
                },
                {
                  value: "carousel",
                  label: "Carousel"
                },
                {
                  value: "collapse",
                  label: "Collapse"
                }
              ]
            }
          ]
        },
        {
          value: "resource",
          label: "Resource",
          children: [
            {
              value: "axure",
              label: "Axure Components"
            },
            {
              value: "sketch",
              label: "Sketch Templates"
            },
            {
              value: "docs",
              label: "Design Documentation"
            }
          ]
        }
      ]
    };
  },
  components: {
    CodeHighlighter
  },
  setup() {
    return {
      code9
    };
  }
});
</script>
